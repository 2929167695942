<template>
  <div class="tab-btn-box">
    <router-link :to="mdspick" class="tab-btn" :class="{ on : path === mdspick }">MD'S PICK</router-link>
    <router-link :to="popular" class="tab-btn" :class="{ on : path === popular }">인기</router-link>
    <router-link :to="ranking" class="tab-btn" :class="{ on : path === ranking }">채널랭킹</router-link>
  </div>
</template>

<script>
export default {
  name: "csCenterTab",
  data() {
    return {
      path: this.$route.path,
      mdspick: '/md',
      popular: '/popular',
      ranking: '/ranking',
    }
  },
  methods: {
  }
}
</script>
